import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_not_found = _resolveComponent("not-found");
  const _component_layout = _resolveComponent("layout");
  return _openBlock(), _createBlock(_component_layout, {
    "is-white-bg": true
  }, {
    default: _withCtx(() => [_createVNode(_component_not_found, {
      class: "not-found-error"
    })]),
    _: 1
  });
}